import { render, staticRenderFns } from "./RecruiterTalentPool.vue?vue&type=template&id=87f663ec&scoped=true&"
import script from "./RecruiterTalentPool.vue?vue&type=script&lang=ts&"
export * from "./RecruiterTalentPool.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f663ec",
  null
  
)

export default component.exports